.projects {
  background-color: $secondary;
  padding-bottom: $grid-gutter-height-mobile;

  @include media-breakpoint-up(md) {
    padding-bottom: 105px;
  }

  &.noslide {
    .projects-container {
      margin-bottom: 30px;
      overflow:hidden;

      &:hover{
        .link-button{
          right: 0;
        }
      }
    }
  }

  &-text {
    background: $white;
    padding: 45px;
  }

  &-image {
    .img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      font-family: 'object-fit:cover';

      @include media-breakpoint-up(lg) {
        height: 350px;
      }

      @include media-breakpoint-up(xl) {
        height: 400px;
      }
    }
  }

  .slick-slide {
    @include media-breakpoint-up(lg) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }


  .slick-next, .slick-prev {
    background-color: rgba(0, 0, 0, 0.5);
    width: 35px;
    height: 55px;
    z-index: 10;

    @include media-breakpoint-up(lg) {
      width: 45px;
    }

    &:focus {
      background-color: rgba(0, 0, 0, 0.8);
      color: $white;
    }

    &:before {
      font-family: 'icons';
      opacity: 1;
      font-size: 30px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  .slick-prev {
    left: 0;

    &:before {
      content: map-get($icons, 'angle-left');
      padding-right: 4px;
    }
  }

  .slick-next {
    right: 0;

    &:before {
      content: map-get($icons, 'angle-right');
      padding-left: 4px;
    }
  }

  &.slick-initialized {
    &.slick-slider {
      &.slick-dotted {
        .slick-dots {
          bottom: 40px;

          .slick-active {
            button {
              &:before {
                font-size: 35px;
                color: $white;
                opacity: 1;
              }
            }
          }

          button {
            &:before {
              font-size: 35px;
              color: $white;
              opacity: 0.25;
            }
          }
        }
      }
    }
  }

  &.slick-slider {
    &.slick-dotted {
      margin-bottom: 0;
    }
  }
}

.link-button{
  fill: #fff;
  background-color: #385b6a;
  border: 2px solid #fff;
  bottom: 0;
  top: 0;
  height: 100%;
  position: absolute;
  right: -50px;
  width: 50px;
  transition: right .3s ease;
}
