.twitter {
  &-wrapper {
    position: relative;
    padding-bottom: 120px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }

    .bg-white {
      height: 100%;
    }
  }

  &-headline {
    display: flex;
    align-items: center;
    padding: $grid-gutter-height-mobile 15px 15px;
    z-index: 10;
    position: static;

    @include media-breakpoint-up(lg) {
      padding: 45px;
    }

    .headline {
      width: 100%;
    }

    .btn {
      z-index: 100;
      width: 142px;
      position: absolute;
      bottom: $grid-gutter-height-mobile;
      left: 0;
      right: 0;
      margin: auto;

      @include media-breakpoint-up(lg) {
        position: static;
        bottom: 0;
      }
    }
  }

  &-container {
    height: 100%;
  }

  &-row {
    height: 100%;

    @include media-breakpoint-up(lg) {
      &:after {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        background-image: url(../../assets/img/twitter-weiss.svg);
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: auto 100%;
        background-position: top 20px right 20px;
        z-index: 0;
      }
    }

    .tweets {
      padding: 15px;

      @include media-breakpoint-up(md) {
        padding: 45px;
      }

      &-wrapper {
        z-index: 1;
      }

      .tweet-link {
        font-weight: $font-weight-light;

        &:hover {
          color: $primary;
          text-decoration: underline;
        }
      }

      .rss_default {
        border-bottom: 1px solid $gray-500;
        padding-top: 15px;
        padding-bottom: 15px;
        text-overflow: ellipsis;
        overflow: hidden;

        &.last {
          padding-bottom: 0;
          border-bottom: none;
        }

        &.first {
          padding-top: 0;
        }

        .tweet-date {
          font-family: $headings-font-family;
          color: $gray-500;
        }
      }
    }
  }
}

