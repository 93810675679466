.about {
  margin-bottom: 40px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 155px;
  }

  .image-col {
    padding: 0;

    @include media-breakpoint-up(md) {
      z-index: 100;
      padding-left: 15px;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 0;
    }
  }

  .img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    font-family: 'object-fit:cover';

    @include media-breakpoint-up(md) {
      width: 300px;
      height: auto;

      @include media-breakpoint-up(lg) {
        width: 350px;
        margin-left: -80px;
      }
    }
  }

  .text-wrapper {
    margin-top: -45px;

    @include media-breakpoint-up(md) {
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 70px;
    }

    .text-box {
      padding: $grid-gutter-height-mobile 15px;

      @include media-breakpoint-up(md) {
        padding: 45px 45px 45px 100px;
        margin-top: 80px;
      }

      @include media-breakpoint-up(lg) {
        position: relative;
        top: 110px;
        right: 15px;
        margin-top: -30px;
        left: -95px;
        padding: $grid-gutter-height $grid-gutter-height 65px 160px;
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .caption {
    &.headline {
      margin-bottom: 15px;
    }
  }
}
