.mod_navigation {
  position: fixed;
  width: 100%;
  background-color: $gray-800;
  top: 0;
  z-index: 1000;
  height: 75px;
  overflow: visible;

  @include media-breakpoint-up(md) {
    height: 102px;
  }

  &.bg-gray-light {
    background-color: rgba(0, 0, 0, 0.7);
    transition: background-color 0.3s;

    .start & {
      background-color: rgba(0, 0, 0, 0.7);
      transition: background-color 0.3s;
    }
  }

  .start & {
    background: transparent;
  }

  .nav-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    @include media-breakpoint-up(md) {
      height: 100%;
    }

    .nav_top {
      width: 100%;
      display: none;

      @include media-breakpoint-up(md) {
        width: auto;
      }

      .start & {
        display: block;
      }

      .icon {
        color: $white;
        font-size: 40px;
      }

      .navbar {
        width: 100%;
        position: relative;
        padding: 75px 0 0;
        text-align: center;

        @include media-breakpoint-up(md) {
          padding: 0;
          text-align: left;
        }

        &-collapse {
          background-color: rgba(0, 0, 0, 0.7);
          transition: background-color 0.3s;
          padding: 15px 0;
          margin: 0 -15px;

          @include media-breakpoint-up(md) {
            background-color: transparent;
            padding: 0;
            margin: 0;
          }
        }

        &-toggler {
          padding: 15px 0 0 0;
          position: absolute;
          right: 0;
          top: 0;
          border: none;

          @include media-breakpoint-up(md) {
            margin-top: 0;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    .nav-item {
      @include media-breakpoint-up(md) {
        padding-right: 40px;

        &:last-child {
          padding-right: 0;
        }
      }

      .nav-link {
        color: $white;
        font-family: $headings-font-family;
        padding-right: 0;
        padding-left: 0;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          display: inline-block;
          position: relative;
          &.active {
            color: $primary;

            &:after {
              border-bottom-color: $primary !important;
            }
          }

          &:after {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid transparent;
            transform: scaleX(0);
            transition: transform .3s;
          }

          &:hover,
          &:focus {
            &:after {
              content: '';
              position: absolute;
              display: block;
              border-bottom: 1px solid $white;
              transform: scaleX(1);
              transition: transform .3s;
            }
          }
        }
      }

      .icon {
        color: $white;
        font-size: 25px;
      }

      .link {
        &:hover {
          .icon-twitter {
            color: #00acee;
            transition: color 0.3s;
          }
        }
      }
    }
  }

  .logo {
    width: 50px;
    font-size: 25px;
    position: absolute;
    left: 15px;
    top: 12px;

    @include media-breakpoint-up(md) {
      width: 70px;
      top: 15px;
    }
  }
}
.mod_customnav{
  width: 100%;
}
