body {
  background: $light-gray;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.headline {
  text-transform: uppercase;
  font-family: $headings-font-family;

  &-light {
    font-weight: $font-weight-light;
  }

  &-col {
    padding-top: $grid-gutter-height-mobile;

    @include media-breakpoint-up(lg) {
      padding-top: $grid-gutter-height;
    }
  }

  .bg-secondary & {
    color: $white;

    .subheadline {
      color: $white;
    }
  }
}

.bg {
  &-gray {
    color: $white;
    background-color: $gray-800;
  }

  &-secondary {
    margin-top: $grid-gutter-height-mobile;

    @include media-breakpoint-up(lg) {
      margin-top: $grid-gutter-height;
    }
  }
}

.subheadline {
  color: $gray-500;
}

.small-headline {
  color: $secondary;
  font-family: $headings-font-family;
}

h1 {
  font-size: 55px;
  font-weight: $font-weight-bold;
}

h2 {
  font-weight: $font-weight-bold;
  font-size: 25px;

  @include media-breakpoint-up(md) {
    font-size: 38px;
  }
}

h3 {
  font-size: 30px;
}

p {
  font-weight: $font-weight-light;
}

a {
  color: $primary;

  &:hover {
    color: $primary;
  }
}

.btn {
  padding: 5px 20px;
  margin-top: 25px;
  border-radius: 0;

  &-primary {
    font-family: $headings-font-family;
    text-transform: uppercase;
  }
}

.text-wrapper {
  margin-top: 140px;

  .start & {
    margin-top: 0;
  }
}


.double-teaser {
  height: 50vh;
  overflow: hidden;
  margin-top: 80px;


  .teaser-bg-wrapper {
    display: flex;
    flex: 1;
    margin-left: $grid-gutter-width/-2;
    margin-right: $grid-gutter-width/-2;

    a {
      width: 100%;
      position: relative;
    }

    .link-wrapper {
      position: absolute;
      left: 0;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      z-index: 100;
      right: 0;
      top: 0;
      bottom: 0;

      .text {
        display: block;
        width: 100%;
        text-align: center;
        font-family: $headings-font-family;
        font-size: 24px;
        color: #fff;
        font-weight: 300;

        @include media-breakpoint-up(md) {
          font-size: 38px;

        }
      }

      .button {
        background-color: #000000b3;
        padding: 10px 15px;
        transition: background-color .3s;
        color: $white;
        text-align: center;

        &:hover {
          background-color: #C2171D;
        }
      }
    }
  }

  .teaser-bg-image {
    flex: 1;
    display: flex;
    background-size: cover!important;
    background-position: center center!important;
    filter: grayscale(100%);
    transition: filter .3s;

    &:hover {
      filter: grayscale(0%);
    }

    &:last-child {
      margin-left: -10vw;
      clip-path: polygon(10vw 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}

.content-wrapper {
  &.fullwidth {
    .content-row {
      .text-wrapper {
        width: 100%;
      }
    }
  }

  &.align-left {
    .content-row {
      justify-content: flex-start;

      .headline-light {
        text-align: left;
      }
    }
  }
}

.slick-track
{
  display: flex !important;
}

.slick-slide
{
  height: inherit !important;
 > div{
    height: 100%;
    .projects-container{
      height: 100%;
      .projects-text{
        height: 100%;
      }
    }
  }
}
