.mod_changelanguage {
  .level_1 {
    display: flex;

    .list-item {
      list-style-type: none;
      font-family: $headings-font-family;
      padding: 8px 0;

      &:first-child {
        margin-right: 15px;
      }
      
      .lang-en, .lang-de {
        color: $white;

        &:hover {
          text-decoration: none;
          color: $primary;
        }

        &.active {
          color: $primary;
        }
      }
    }
  }
}
