.contact {
  margin-top: $grid-gutter-height-mobile;
  margin-bottom: $grid-gutter-height-mobile;

  @include media-breakpoint-up(lg) {
    margin-top: $grid-gutter-height;
    margin-bottom: $grid-gutter-height;
    position: relative;

    &:after {
      content: '';
      width: 65px;
      height: 100%;
      position: absolute;
      right: -65px;
      top: 0;
      background-color: $white;
    }
  }

  &-row {
    margin-right: 0;
    margin-left: 0;

    @include media-breakpoint-up(lg) {
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  .social-icons {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      margin-bottom: 0;
    }

    .link {
      color: $black;
      transition: color 0.3s;

      &:hover {
        color: $primary;
      }

      &-twitter {
        &:hover {
          color: #00acee;
        }
      }
    }

    .icon {
      &-twitter {
        margin-right: 25px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 5px;
          margin-right: 0;
        }
      }

      &:before {
        font-size: 35px;
      }
    }
  }

  &-details {
    padding-top: $grid-gutter-height-mobile;

    @include media-breakpoint-up(md) {
      padding: 45px;
    }

    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-height 0 $grid-gutter-height 65px;
    }
  }

  &-form {
    display: flex;
    align-items: center;
    padding-top: $grid-gutter-height-mobile;
    padding-bottom: $grid-gutter-height-mobile;

    @include media-breakpoint-up(md) {
      padding: 0 45px 45px 45px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 0 0 60px;
    }

    .ce_form {
      width: 100%;
    }

    .text, .textarea {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid $black;
      font-weight: $font-weight-light;
      font-size: 13px;
      color: $gray-500;
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    .widget-text {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 35px;

      @include media-breakpoint-up(md) {
        width: 50%;
      }

      input {
        &:-internal-autofill-selected {
          background-color: transparent !important;
        }
      }
    }

    .widget-textarea {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
    }

    .submit_container {
      width: 100%;

      @include media-breakpoint-up(md) {
        padding-left: 15px;
      }
    }
  }
}
