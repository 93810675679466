.content-wrapper {
  margin-top: $grid-gutter-height-mobile;

  @include media-breakpoint-up(lg) {
    margin-top: $grid-gutter-height;
  }

  &.float_right {
    float:none!important;
    .content-row {
      justify-content: flex-start;

      .image-wrapper {
        @include media-breakpoint-up(md) {
          right: 15px;
          left: inherit;
        }

        @include media-breakpoint-up(lg) {
          right: -80px;
        }
      }

      .headline-light {

        @include media-breakpoint-up(md) {
          text-align: left;
        }
      }

      .text-wrapper {
        @include media-breakpoint-up(md) {
          padding-right: 0;
        }
      }
    }
  }

  .content-row {
    flex-wrap: nowrap;
    position: relative;
    justify-content: flex-end;

    h4 {
      font-family: $headings-font-family;
      font-size: 25px;
      line-height: 1.3;

      @include media-breakpoint-up(md) {
        font-size: 30px;
        line-height: 1.5;
      }
    }

    .image-wrapper {
      position: absolute;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 52%;
        left: 15px;
      }

      @include media-breakpoint-up(lg) {
        left: -80px;
      }

      .img {
        object-fit: cover;
        font-family: 'object-fit:cover';
        height: 300px;
        width: 100%;

        @include media-breakpoint-up(md) {
          height: 300px;
        }

        @include media-breakpoint-up(lg) {
          height: 450px;
        }
      }
    }

    .text-wrapper {
      margin-top: 245px;
      width: 100%;
      z-index: 100;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;

      @include media-breakpoint-up(md) {
        width: 60%;
        margin-top: 40px;
        padding-left: 0;

      }

      .text-box {
        padding: 65px 15px 15px;

        @include media-breakpoint-up(md) {
          padding: 45px;
          margin-top: 45px;
        }

        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .headline-light {
      font-size: 25px;
      text-align: center;
      position: absolute;
      top: 15px;
      left: 0;
      right: 0;

      @include media-breakpoint-up(md) {
        margin-top: 15px;
        position: static;
        text-align: right;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 85px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 38px;
      }
    }
  }

  &:last-child {
    margin-bottom: $grid-gutter-height-mobile;

    @include media-breakpoint-up(lg) {
      margin-bottom: $grid-gutter-height;
    }
  }
}
