// Generated by grunt-webfont
// Based on https://github.com/endtwist/fontcustom/blob/master/lib/fontcustom/templates/fontcustom.css



$icons-font-path : "../../assets/fonts/" !default;

@font-face {
  font-family:"icons";
  src:url($icons-font-path + "icons.eot?53d5f903e4f69f650c70a48c5c2a94f5");
  src:url($icons-font-path + "icons.eot?53d5f903e4f69f650c70a48c5c2a94f5#iefix") format("embedded-opentype"),
		url($icons-font-path + "icons.woff2?53d5f903e4f69f650c70a48c5c2a94f5") format("woff2"),
		url($icons-font-path + "icons.woff?53d5f903e4f69f650c70a48c5c2a94f5") format("woff"),
		url($icons-font-path + "icons.ttf?53d5f903e4f69f650c70a48c5c2a94f5") format("truetype"),
		url($icons-font-path + "icons.svg?53d5f903e4f69f650c70a48c5c2a94f5#icons") format("svg");
  font-weight:normal;
  font-style:normal;
}


 // Bootstrap Overrides
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family:"icons";
  display:inline-block;
  line-height:1;
  font-weight:normal;
  font-style:normal;
  speak:none;
  text-decoration:inherit;
  text-transform:none;
  text-rendering:auto;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}



// Icons

      
           .icon-angle-left:before {
        content:"\f101";
      }


      
           .icon-angle-right:before {
        content:"\f102";
      }


      
           .icon-bars:before {
        content:"\f103";
      }


      
           .icon-linkedin-in:before {
        content:"\f104";
      }


      
           .icon-twitter:before {
        content:"\f105";
      }



// Icons as a map
$icons: (
  angle-left: "\f101",
  angle-right: "\f102",
  bars: "\f103",
  linkedin-in: "\f104",
  twitter: "\f105",
);

