//System Variables
// Custom Iconfont Path if needed
$icons-font-path : "./fonts/";

// Usage of webfont in :after
//    font-family: icons;
//    content: map-get($icons, 'call_made');

// includes
// @import url('');
@import 'bootstrap';
@import 'icons';
@import 'header';
@import 'nav';
@import 'base';
@import 'content';
@import 'projects';
@import 'contact';
@import 'about';
@import 'twitter';
@import 'changelanguage';
@import 'footer';
@import url('https://use.typekit.net/lvv5lhg.css');
@import '../../node_modules/slick-carousel/slick/slick.scss';
@import '../../node_modules/slick-carousel/slick/slick-theme.scss';


// global styles


html {

}
