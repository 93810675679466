.footer {
  border-top: 1px solid $gray-500;
  margin-top: $grid-gutter-height-mobile;

  .start & {
    border: none;
  }

  .nav-footer {
    list-style-type: none;
    margin-top: $grid-gutter-height-mobile;
    margin-bottom: $grid-gutter-height-mobile;
    padding-left: 0;

    .nav-item {
      margin-bottom: 5px;
      font-family: $headings-font-family;
      text-transform: uppercase;
      color: $dark-gray;

      &:last-child {
        margin-bottom: 0;
      }

      .nav-link {
        padding: 0;
        color: $dark-gray;

        @include media-breakpoint-up(lg) {
          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}
