.header {
  background-size: cover;
  height: 300px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @include media-breakpoint-up(md) {
    height: 500px;
  }

  @include media-breakpoint-up(lg) {
    height: 800px;
  }

  &-logo {
    display: block;
    max-width: 80%;
    width: 100%;
    padding: 0 10px;
    margin: 0 auto 16px auto;

    @include media-breakpoint-up(md) {
      max-width: 485px;
    }

    @include media-breakpoint-up(lg) {
      height: 44px;
      margin: 0 0 25px 50%;
      padding: 0;
      width: 100%;
      object-fit: contain;
    }
  }

  .img {
    width: 100%;
    height: 100%;
    max-height: 800px;
    object-fit: cover;
    font-family: 'object-fit:cover';
  }

  &-text {
    color: $white;
    text-align: center;
    margin-top: 140px;
    position: absolute;
    width: 100%;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }

    .header-headline {
      font-size: 40px;

      @include media-breakpoint-up(md) {
        font-size: 60px;
      }
    }

    .header-subheadline {
      @include media-breakpoint-up(lg) {
        text-transform: uppercase;
        text-align: left;
        padding-left: 50%;
      }

      h2 {
        margin-bottom: 20px;
        font-size: 17px;
        @include media-breakpoint-up(md) {
          font-size: 20px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 23px;
        }
      }

      h3 {
        font-size: 15px;
        @include media-breakpoint-up(md) {
          font-size: 17px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 20px;
        }
      }


    }
  }
}
